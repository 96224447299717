<template>

  <div :class="wrapperClass" :key="uuid" class="form-group">

    <label :for="idElement()" v-if="label || semindice" :title="label">
      {{label}}

      <slot name="label"></slot>

      <slot name="extra-label-slot"></slot>

    </label>

    <slot name="actions"></slot>

    <div class="input-group">
      <slot name="input"></slot>

      <template v-if="inputType"> <!-- ====================================================== -->
        <input :data-testid="dataTestid" :id="idElement()" :name="idElement()" :key="idElement()" @change="changeEvent" :type="inputType" @keyup.enter="$emit('enter')" ref="input" @keypress="$emit('keyp')"
               v-bind="attrs" v-model="model" v-on="events" :min="min != '' ? min : (inputType == 'date' ? '1111-01-01' : '')" :max="max != '' ? max : (inputType == 'date' ? '9999-12-31' : (inputType == 'month' ? '9999-12' : ''))" >
      </template>

      <template v-if="type == 'number'"> <!-- ====================================================== -->
        <input :data-testid="dataTestid" :id="idElement()" :key="idElement()" @keyup.enter="$emit('enter')" ref="input"
               type="number" v-bind="attrs" v-model.number="model" v-on="events" :min="min">
      </template>

      <template v-else-if="mask"> <!-- ====================================================== -->
        <input :id="idElement()" :key="idElement()" @keyup.enter="$emit('enter')" ref="input" type="tel"
               v-bind="attrs" v-mask="mask" v-model="model" v-on="events">
      </template>

      <template v-else-if="type == 'currency'"> <!-- ====================================================== -->
<!--        <money ></money>-->
        <currency-input
          :id="idElement()" :key="idElement()" :data-testid="dataTestid" ref="input" v-bind="attrs" v-model="model" v-on="events"
          :currency="null" :distraction-free="{hideNegligibleDecimalDigits : true, hideGroupingSymbol : false}"
          locale="de-DE"
        />
      </template>

      <template v-else-if="type == 'textarea'"> <!-- ====================================================== -->
        <textarea :id="idElement()" :key="idElement()" :data-testid="dataTestid" ref="input" rows="3"
                  v-bind="attrs" v-model.trim="model" v-on="events"></textarea>
      </template>

      <template v-else-if="type == 'select'"> <!-- ====================================================== -->
        <select :id="idElement()" :key="idElement()" @keyup.enter="$emit('enter')" ref="input" v-bind="attrs"
                v-model="model" v-on="events" :disabled="disabled" :data-testid="dataTestid" data-test="select-option">

          <option hidden>{{placeholder || '-'}}</option>

          <option :disabled="option.disabled" :hidden="option.hidden" :key="'options'+index" :value="option.id"
                  v-for="(option, index) in options">
            {{ option.name || option.nome }}
          </option>

        </select>
      </template>

      <template v-else-if="type == 'select-group'"> <!-- ====================================================== -->
        <select :id="idElement()" :key="idElement()" @keyup.enter="$emit('enter')" ref="input" v-bind="attrs"
                v-model="model" v-on="events" :disabled="disabled" :data-testid="dataTestid">

          <option hidden>{{placeholder || '-'}}</option>

          <optgroup :disabled="group.disabled" :hidden="group.hidden" :key="'group'+index" v-for="(group, index) in options" :label="group.name || group.nome">
            <option :disabled="option.disabled" :hidden="option.hidden" :key="'options'+index" :value="option.id"
                    v-for="(option, index) in group.options">
              {{ option.name || option.nome }}
            </option>
          </optgroup>

        </select>
      </template>

      <template v-else-if="type == 'optgroup'"> <!-- ====================================================== -->
        <select :id="idElement()" :key="idElement()" @keyup.enter="$emit('enter')" ref="input" v-bind="attrs"
                v-model="model" v-on="events" :disabled="disabled" :data-testid="dataTestid" data-test="select-option">

          <option hidden>{{placeholder || '-'}}</option>

          <optgroup :label="optgroup.name || optgroup.nome" v-for="(optgroup, i) in options" :key="'optgroup'+i" >
            <option :disabled="option.disabled" :hidden="option.hidden" :key="'optgroup'+i+'options'+index" :value="option.id"
                    v-for="(option, index) in optgroup.options">
              {{ option.name || option.nome }}
            </option>
          </optgroup>

        </select>


      </template>

      <template v-else-if="type == 'select-name'"> <!-- ====================================================== -->

        <select :id="idElement()" :key="idElement()" @keyup.enter="$emit('enter')" ref="input" v-bind="attrs"
                v-model="model" v-on="events">

          <option hidden>{{placeholder || '-'}}</option>

          <option :disabled="option.disabled" :hidden="option.hidden" :key="'options'+index" :value="option.id"
                  v-for="(option, index) in options">
            {{ option[nameOptionSelect] }}
          </option>

        </select>

      </template>

      <template v-else-if="type == 'v-select'"> <!-- ====================================================== -->
        <v-select :data-testid="dataTestid" :clearable="clearable" :filterBy="filterBy" :id="idElement()" :key="idElement()" :options="options"
                  :reduce="o => o.id" :value="model" @change="changeEvent" @input="update" @keyup.enter="$emit('enter')"
                  :selectable="(o) => !o.hidden && !o.disabled" :disabled="disabled"
                  class="vs-drop-active" label="nome" :title="titleVSelect"
                  append-to-body :calculate-position="withPopper"
                  max-height="200px" ref="input" v-bind="attrs">
<!--          max-height="200px" ref="input" v-bind="attrs" v-on="events">-->
          <template v-slot:option="option" v-if="setOrderedList">
            <span :class="`ml-${option.ordem != 0 ? option.ordem+2: 0}`">{{option.nome }}</span>
          </template>
          <template #no-options>Nenhum item encontrado</template>
        </v-select>
      </template>

      <template v-else-if="type == 'v-multi-select'"> <!-- ====================================================== :append-to-body="true" -->
        <v-select :clearable="true" :filterBy="filterBy" :id="idElement()" :key="idElement()" :options="options"
                  :reduce="o => o.id" :value="model" @change="changeEvent" @input="update" @keyup.enter="$emit('enter')"
                  :selectable="(o) => !o.hidden && !o.disabled"
                  :data-testid="dataTestid"
                  class="vs-drop-active" label="nome" multiple
                  append-to-body :calculate-position="withPopper"
                  max-height="200px" ref="input" v-bind="attrs">
          <!--          max-height="200px" ref="input" v-bind="attrs" v-on="events">-->
          <template v-slot:option="option" v-if="setOrderedList">
            <span :class="`ml-${option.ordem != 0 ? option.ordem+2: 0}`">{{option.nome }}</span>
          </template>
          <template #no-options>Nenhum item encontrado</template>
        </v-select>
      </template>

      <template v-else-if="type == 'tags'"> <!-- ====================================================== -->
        <v-select :id="idElement()" :key="idElement()" :options="options" :data-testid="dataTestid"
                  :taggable="taggable" multiple :value="model" @change="changeEvent" @input="update" @keyup.enter="$emit('enter')"
                  class="vs-drop-active" label="nome"
                  append-to-body :calculate-position="withPopper"
                  max-height="200px" ref="input" v-bind="attrs">
          <!--          max-height="200px" ref="input" v-bind="attrs" v-on="events">-->
          <template v-slot:option="option" v-if="setOrderedList">
            <span :class="`ml-${option.ordem != 0 ? option.ordem+2: 0}`">{{option.nome }}</span>
          </template>
          <template #no-options>Nenhum item encontrado</template>
        </v-select>
      </template>

      <template v-else-if="type == 'radio'"> <!-- ====================================================== -->

        <div class="custom-controls-stacked">

          <div :key="idElement(i)" class="form-check form-check-inline" v-for="(option, i) in options">

            <input :disabled="disabled" :readonly="readonly" :id="idElement(i)" :key="idElement(i)" :name="idElement(i)" :value="option.id"
                   @change="changeEvent($event)" :data-testid="dataTestid"
                   class="form-check-input" type="radio" v-model="model"
                   v-on="events">

            <label :for="idElement(i)" class="form-check-label">{{ option.nome }}</label>

          </div>

        </div>

      </template>

      <template v-else-if="type == 'checkbox'"> <!-- ====================================================== -->

        <div class="custom-controls-stacked">

          <div :key="idElement(i)" class="form-check form-check-inline" v-for="(option, i) in options">

            <input :disabled="disabled" :readonly="readonly" :id="idElement(i)" :key="idElement(i)" :value="option.id" :true-value="option.id"
                   @change="changeEvent($event)" class="form-check-input" type="checkbox"
                   v-model="model">

            <label :for="idElement(i)" class="form-check-label">{{ option.nome }}</label>

          </div>
        </div>

      </template>

      <template v-else-if="type == 'autocomplete'"> <!-- ====================================================== -->

        <suggestions :disabled="disabled" :readonly="readonly" :onInputChange="searchQueryAutocomplete" :onItemSelected="update"
                     :options="{debounce : 300, inputClass: 'form-control form-control-xs'}"
                     :id="idElement()" :key="idElement()"
                     @keyup.enter="$emit('enter')"
                     ref="input" v-model.trim="model"
                     v-on="events">

        </suggestions>

      </template>

      <template v-else-if="type == 'editor' || type == 'classic-editor' || type == 'tiny'"> <!-- ====================================================== -->
        <editor :init="tinyConfigComputed" :id="idElement()" :key="idElement()"
                :disabled="disabled" :readonly="readonly"
                :data-testid="dataTestid"
                :html-editor="htmlEditor"
                v-model.trim.lazy="model"
                v-on="events" v-bind="attrs"
                ref="editortexto"
                model-events="blur change keyup undo redo" @onkeyup="onkeyup($event)"
                api-key="go5oky3bl2quqkutip700bunx6iw17b4obiffo48n4bz04p2"
                cloud-channel="7"
        />
      </template>

      <template v-else-if="type == 'editor-ato'"> <!-- ====================================================== -->
        <editor :init="tinyConfigAtoComputed" :id="idElement()" :key="idElement()"
                :disabled="disabled" :readonly="readonly"
                :data-testid="dataTestid"
                :html-editor="htmlEditor"
                v-model.trim.lazy="model"
                v-on="events" v-bind="attrs"
                ref="editortexto"
                model-events="blur change keyup undo redo" @onkeyup="onkeyup($event)"
                api-key="go5oky3bl2quqkutip700bunx6iw17b4obiffo48n4bz04p2"
                cloud-channel="7"
        />
      </template>

      <template v-else-if="type == 'inline-editor'"> <!-- ====================================================== -->
        <editor :init="tinyInlineConfigComputed" :id="idElement()" :key="idElement()"
                :disabled="disabled" :readonly="readonly"
                v-model.trim.lazy="model"
                v-on="events" v-bind="attrs"
                ref="editortexto"
                model-events="blur change keyup undo redo" @onkeyup="onkeyup($event)"
                api-key="go5oky3bl2quqkutip700bunx6iw17b4obiffo48n4bz04p2"
                cloud-channel="7"
        />
      </template>

      <template v-else-if="type == 'readonly-editor'"> <!-- ====================================================== -->
        <editor :init="readonlyConfigComputed" :id="idElement()" :key="idElement()"
                :disabled="true" :readonly="true"
                v-model.trim.lazy="model"
                v-on="events" v-bind="attrs"
                ref="editortexto"
                model-events="blur change keyup undo redo" @onkeyup="onkeyup($event)"
                api-key="go5oky3bl2quqkutip700bunx6iw17b4obiffo48n4bz04p2"
                cloud-channel="7"
        />
      </template>

      <template v-else-if="type == 'file'">
        <div :class="{'img': model}" class="upload">
          <file-upload
            :custom-action="upload"
            :drop="false"
            :drop-directory="false"
            :id="idElement()" :key="idElement()"
            :multiple="false"
            :thread="1"
            :disabled="disabled"
            :data-testid="dataTestid"
            @input-file="start"
            accept="image/png,image/jpeg"
            class="btn btn-sm btn-outline-primary mx-1 pointer"
            extensions="jpg,jpeg,png"
            ref="upload" v-if="!model" v-model="file">

            <i class="fa fa-upload pointer" v-if="(!$refs.upload || !$refs.upload.active) && !loading"></i>

            <span aria-hidden="true"
                  class="ml-1 spinner-border spinner-border-sm" role="status" v-else></span>
          </file-upload>

          <v-button v-else-if="!disabled" :button="false" :popover="true" :run="remover" class="btn btn-sm btn-outline-primary mx-1 pointer"
                    position="dropleft">
            <i class="fal fa-times" title="Excluir"></i>
          </v-button>

          <img :src="`${server}/api/anexo/public/${model}/download`" v-if="model"/>
          <div class="alert alert-outline-secondary" v-else>
            Nenhuma imagem anexada.
          </div>
        </div>
      </template>

      <div class="spinner-border spinner-border-sm text-primary" role="status"
           style="position: absolute;right: 10px;top: 25%;z-index: 9999;"
           v-if="loading"></div>

      <slot :class="{'is-invalid' : error || showError}"></slot>

      <div class="invalid-feedback" v-if="error || showError">{{error || showError}}</div>

    </div>

    <slot name="rodape"></slot>

  </div>

</template>

<script>

import AnexoBusiness from "@/business/AnexoBusiness";
import FrontBusiness from "@/business/FrontBusiness";
import utils from "@/commons/Utils";

import Editor from '@tinymce/tinymce-vue'
import Suggestions from 'v-suggestions'
import VueSelect from 'vue-select'
import {mask} from 'vue-the-mask'
import FileUpload from "vue-upload-component";
import EnumAnexoEntidade from "@/business/cloudStorage/EnumAnexoEntidade";

import { createPopper } from '@popperjs/core';
export default {
    name: "V-Input",
    directives: {mask},
    components: {
      'v-select': VueSelect,
      Suggestions,
      FileUpload,
      'editor': Editor
    },
    props: {
      value: {},
      default: {},
      id: String,
      entidade: String,
      checklist: {type: Boolean, default: false},
      clearable: {type: Boolean, default: false},
      label: String,
      type: String,
      placeholder: String,
      taggable: {type: Boolean, default: true},
      inputProps: Object,
      options: {type: Array, default: () => [{id: true, nome: 'Sim'}, {id: false, nome: 'Não'}]},
      loading: {type: Boolean, default: false},
      error: String,
      readonly: {type: Boolean, default: false},
      disabled: {type: Boolean, default: false},
      valid: {type: Boolean, default: false},
      focus: {type: Boolean, default: false},
      searchQuery: Function,
      nameOptionSelect: String,
      idindice: {type: String, default: ''},
      semindice: {type: Boolean, default: false},
      setOrderedList: {type: Boolean, default: false},
      min: {type: String, default: ''},
      max: {type: String, default: ''},
      dataMin: {type: String, default: '1800-01-01'},
      extraPlugins: {type: Array},
      pushEditorConfig: {type: Object},
      tinyOptions: {type: Object, default: () => ({})},
      startsWith: {type: Boolean, default: false},
      dataTestid: String,
      masks: {type: Array, default: () => ([])},
      htmlEditor: {type: Boolean, default: true},
      desativarPermissao: {type: Boolean, default: false},
    },

    mounted() {
      if ((this.type === 'editor' || this.type === 'classic-editor' || this.type === 'tiny') && this.disabled) {
        let iframe = this.$el?.querySelector("iframe");
        if (iframe) iframe.contentDocument.body.style.cursor = 'not-allowed';
      }
    },

  created() {
      //tinymcespellchecker
    if(this.htmlEditor){
      this.tinyConfig.plugins.push('code');
    }
  },

  watch: {
      value: {
        immediate: true,
        handler(val) {
          if ((val === null || val === undefined) && this.default) {
            this.update(this.default);
          } else if (val != this.model) {
            if (this.type == 'checkbox') {
              val = (Array.isArray(val) ? val : [val]).filter(a => a);
              if(this.options.length){
                val = val.filter(o => this.options.find(i => i.id == o));
              }
            }
            this.$set(this, 'model', val);
          }

        }
      },
    },

    computed: {

      searchQueryAutocomplete() {
        return this.searchQuery || this.searchQueryDefault;
      },

      wrapperClass() {
        return `input-${this.type} ${this.error || this.showError ? 'is-invalid' : ''} ${this.valid ? 'is-valid' : ''}`
      },

      inputType() {
        return ['text', 'date', 'time', 'datetime-local', 'month', 'email', 'color', 'password', 'text-compare'].includes(this.type) ? this.type : false;
      },

      mask() {
        switch (this.type) {
          case 'cep' :
            return '#####-###';
          case 'cpf' :
            return '###.###.###-##';
          case 'cnpj' :
            return '##.###.###/####-##';
          case 'telefone' :
            return ['(##) ####-####', '(##) #####-####'];
          case 'cpfCnpj':
            return ['###.###.###-##', '##.###.###/####-##'];
          case 'mask' :
            return this.masks;
          default:
            return false;
        }
      },

      classes() {
        return `form-control form-control-xs ${this.error || this.showError ? 'is-invalid' : ''} ${this.valid ? 'is-valid' : ''} ${this.disabled ? 'not-allowed' : ''}`.trim()
      },

      attrs() {
        let obj = {
          ...(this.inputProps || {}),
          class: `${this.classes || ''}`,
          id: this.uuid,
          placeholder: this.placeholder,
          disabled: this.disabled,
          readonly: this.readonly,
        };

        return obj;
      },

      fixedId(){
        return `${this.type}-${this.idindice != '' ? this.idindice + '-' : ''}${this.uuid}`
      },

      server(){
        return window.server.API;
      },

      themeEditor(){
        return this.$root.theme == 'dark' ? 'dark' : 'default'
      },

      skinThemeEditor(){
        return this.$root.theme == 'dark' ? 'oxide-dark' : 'oxide'
      },

      tinyConfigComputed(){
        return {...this.tinyConfig, content_css: this.themeEditor, skin: this.skinThemeEditor, ...(this.tinyOptions||{})};
      },

      tinyConfigAtoComputed(){

        //https://www.tiny.cloud/solutions/dms-editor/
        let htmlBackground = '#eceef4';
        let background = '#fff';
        if(this.$root.theme == 'dark'){
          htmlBackground = '#1C1E24';
          background = '#222f3e';
        }

        let maxWidth = this.$root.config?.outrosTemplates.editorAtoLargura || '100%';
        let padding = this.$root.config?.outrosTemplates.editorAtoMargem || '2rem';
        let normalEditor = maxWidth == '100%' || maxWidth == '' || maxWidth == '0';

        let content_style = normalEditor ? '' : `
            body {
              background: ${background};
            }

            /* Disable the blue "focus" border for the editable region */
            .editable-section:focus-visible {
              outline: none !important;
            }

            .header,
            .footer {
              font-size: 0.8rem;
              color: #ddd;
            }

            .header {
              display: flex;
              justify-content: space-between;
              padding: 0 0 1rem 0;
            }

            .header .right-text {
              text-align: right;
            }

            .footer {
              padding:2rem 0 0 0;
              text-align: center;
            }

            /* Apply page-like styling */
            @media (min-width: 500px) {
              html {
                background: ${htmlBackground};
                min-height: 100%;
                padding: 0.5rem;
              }

              body {
                background-color: ${background};
                box-shadow: 0 0 4px rgba(0, 0, 0, .5);
                box-sizing: border-box;
                margin: 1rem auto 0;
                width: ${maxWidth};
                min-height: calc(100vh - 1rem);
                padding: ${padding};
              }
            }
          `;

        return {
          ...this.tinyConfigComputed,
          editable_root: true,
          min_height: 200,
          max_height: 600,
          width: "100%",
          content_style: content_style,
        };
      },

      tinyInlineConfigComputed(){
        return {
          ...this.tinyConfigComputed
        };
      },

      readonlyConfigComputed(){
        return {
          ...this.tinyConfigComputed,
          max_height: '999999999px',
          toolbar: false
        };
      },

      titleVSelect(){
        return (this.options || []).find(e => e.id === this.model)?.nome;
      },

    },

    data() {
      let val = this.value ? utils.clone(this.value) : null;

      if (this.type == 'checkbox') {
        val = (Array.isArray(val) ? val : [val]).filter(a => a);
        if(this.options.length){
          val = val.filter(o => this.options.find(i => i.id == o));
        }
        if (val.length != this.value?.length) {
          this.update(val);
        }
      }

      if (this.type == 'radio') {
        if (val === "false") {
          this.update(false);
        }
      }

      // if (this.type == 'datetime-local' && !!val) {
      //   val = val.replace('T', ' ');
      // }

      if (this.type == 'autocomplete' && !!val) {
        val = '';
      }

      return {
        model: val,
        file: [],
        showError: null,
        uuid: utils.uuid(),
        events: {
          'blur': this.blurEvent,
          'change': this.changeEvent,
          'input': this.inputEvent,
          'keyup.enter': this.enterEvent,
          'enter': this.enterEvent,
          'keypress': this.keypressEvent,
        },

        tinyConfig: {
          browser_spellcheck: true,
          license_key: "go5oky3bl2quqkutip700bunx6iw17b4obiffo48n4bz04p2",
          apiKey: "go5oky3bl2quqkutip700bunx6iw17b4obiffo48n4bz04p2",
          // cache_suffix: '?v=5.8.0',
          // contextmenu: true,
          min_height: 200,
          max_height: 600,
          width: "100%",
          menubar: false,
          language: 'pt_BR',
          content_css: 'dark',
          skin: 'oxide-dark',
          branding: false,
          elementpath: false,
          // verify_html : false,
          remove_trailing_brs: true,
          entity_encoding : "raw",
          encoding: 'xml',
          statusbar: false,
          powerpaste_allow_local_images: true,
          powerpaste_word_import: 'prompt',
          powerpaste_html_import: 'prompt',
          paste_postprocess: function (plugin, args) {
            const content = args.node;

            if (args.mode === 'clean') {
              ['strong', 'em', 'strike', 'u, span[style*="underline"]', 'p'].forEach(tag => {
                const elementsToRemove = content.querySelectorAll(tag);

                elementsToRemove.forEach(element => {
                  const span = document.createElement('span');
                  span.innerHTML = element.innerHTML;
                  element.replaceWith(span);
                });
              });

              ['br'].forEach(tag => {
                const elementsToRemove = content.querySelectorAll(tag);

                elementsToRemove.forEach(element => {
                  element.remove();
                });
              });
            }

            const elementsWithStyle = content.querySelectorAll('[style]');

            elementsWithStyle.forEach(function(element) {
              const style = element.getAttribute('style');

              if (style.includes('text-decoration-line: underline') || style.includes('text-decoration: underline')) {
                const underlineElement = document.createElement('u');
                underlineElement.innerHTML = element.innerHTML;

                element.replaceWith(underlineElement);
              } else {
                const newStyle = style
                  .replace(/color\s*:\s*[^;]+;?/g, '')            // Remove color
                  .replace(/font-family\s*:\s*[^;]+;?/g, '')      // Remove font
                  .replace(/background-color\s*:\s*[^;]+;?/g, '') // Remove background-color
                  .trim();

                if (newStyle) {
                  element.setAttribute('style', newStyle);
                } else {
                  element.removeAttribute('style');
                }
              }
            });
          },
          // spellchecker_active: true,
          // spellchecker_language: 'pt_BR',
          // typography_langs: [ 'pt-BR' ],
          // typography_default_lang: 'pt-BR',
          // plugins:
          //   'advlist searchreplace advcode media table powerpaste advtable visualblocks'
          // , //['powerpaste'],
          plugins: [
            'image', 'lists', 'media', 'searchreplace', 'table', /*'visualblocks',*/ 'fullscreen', 'autoresize',
            'powerpaste', /*'advtable',*/ 'editimage',
          ],
          toolbar:
            'fullscreen styles bold italic underline forecolor backcolor \
            alignleft aligncenter alignright alignjustify \
            bullist numlist outdent indent blockquote table removeformat pastetext image code ', //fontsizeselect lineheight
          table_default_attributes: {
            border: '1'
          },
          table_default_styles: {
            'border-collapse': 'collapse', 'width': '100%'
          },
          table_advtab: false,
          table_cell_advtab: false,
          table_row_advtab: false,
          table_toolbar: '',

          paste_data_images: true,
          image_title: true,
          automatic_uploads: true,
          file_picker_types: 'image',
          file_picker_callback: function (cb, value, meta) {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');

            input.onchange = function () {
              let file = this.files[0];

              let reader = new FileReader();
              // reader.onload = function () {
              //   let id = 'blobid' + (new Date()).getTime();
              //   let blobCache =  tinymce.activeEditor.editorUpload.blobCache;
              //   let base64 = reader.result.split(',')[1];
              //   let blobInfo = blobCache.create(id, file, base64);
              //   blobCache.add(blobInfo);
              //   cb(blobInfo.blobUri(), { title: file.name });
              // };

              reader.onload = function(e) {
                let img = new Image();
                img.src = e.target.result;

                img.onload = function() {
                  let canvas = document.createElement('canvas');
                  let ctx = canvas.getContext('2d');

                  let width = 700;
                  let scale = width / img.width;
                  canvas.width = width;
                  canvas.height = img.height * scale;

                  ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                  let base64String = canvas.toDataURL('image/jpeg', 0.9);
                  cb(base64String, {
                    title: file.name
                  });
                };
              };

              reader.readAsDataURL(file);
            };

            input.click();
          },
          //inline_styles: false,
          fix_list_elements : false,
          extended_valid_elements : "strong/b,em/i,u",
          element_format: 'xhtml',
          styles: {
            bold : {inline : 'strong' },
            italic: { inline: 'em' },
            underline: { inline: 'u' },
          },
          formats: {
            bold : {inline : 'strong' },
            italic: { inline: 'em' },
            underline: { inline: 'u' },
          },
          // invalid_elements:'span',

          style_formats_merge: true,
          style_formats: [
            { title: 'Parágrafo Comum', block: 'p' },
            { title: 'Parágrafo Indentado', block: 'p', styles: { textIndent: '40pt' } },
          ],

          // indentation: '20pt',
          // external_plugins: {
          //   powerpaste: "/js/tinymce/powerpaste/plugin.js"
          // },

          relative_urls: false,
          remove_script_host: false,
          document_base_url: window.server.API,

          content_style: `
            u { text-decoration: underline; }
          `
        }

      }
    },

    methods: {

      idElement(i) {
        return `${this.fixedId}${(['checkbox', 'radio'].includes(this.type) && i != null) ? '-in-' + i : ''}`;
      },

      blurEvent(e) {
        this.$set(this, 'showError', null);
        if (['date', 'datetime-local'].includes(this.type) && this.model && (this.$moment(this.model).isBefore(this.$moment(this.dataMin)) || !this.$moment(this.model).isValid())) {
          this.$set(this, 'showError', 'Data inválida');
        }
        this.emit('blur', e);
      },

      enterEvent() {
        this.emit('enter');
      },

      keypressEvent(e) {
        this.emit('keypress', e);
      },

      changeEvent(e) {
        if (this.type == 'checkbox' || this.type == 'radio') {
          this.update(this.model, e);
        } else {
          let value = (e || {}).target ? e.target.value : e;
          this.emit('change', e);
        }
      },

      inputEvent(e) {
        if (this.type == 'checkbox') {
          this.update(this.model, e);
        } else {
          let value = (e || {}).target ? e.target.value : e;
          this.update(value, e);
        }
      },

      update(val, e) {
        this.emit('input', val);
        let changeValue = e != undefined ? e : val;
        this.emit('change', changeValue);
        if (this.type === 'v-select') this.emit('blur', changeValue);
        this.$set(this, 'model', val);
      },

      onkeyup(e){
        this.emit('onkeyup', e);
      },

      emit(event, val) {
        this.$emit(event, val);
      },

      toggleList(value) {
        this.model = Array.isArray(this.model) ? this.model : [];

        if (this.model.includes(value)) {
          this.model = this.model.filter(e => e != value);
        } else {
          this.model.push(value);
        }

        this.update(this.model);
      },

      vSelectForce(item) {
        this.$refs.input.clearSelection();
        if (item) {
          this.$refs.input.updateValue(item);
        } else {
          this.$refs.input.updateValue(item);
        }
        this.$refs.input.closeSearchOptions();
      },

      searchQueryDefault(query = '') {

        let q = query.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");

        if (!q?.length) {
          return null
        }

        return (this.options || [])?.filter((opt) => {
          return opt.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(q)
        });

      },

      filterBy(option, label, search) {
        let q = search.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
        let s = (label + "").trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
        if(this.startsWith) {
            return s.startsWith(q);
        }
        return s.includes(q);
      },

      async upload(file) {
          if(!this.disabled) {
              let anexos;
              if (this.model) await AnexoBusiness.deleteById(this.model).catch(e => FrontBusiness.showError('', e.message));
              const entidade = EnumAnexoEntidade.getEnumAnexoEntidade(this.entidade);
              return await AnexoBusiness.inserir(file.file, entidade, this.id, undefined, this.checklist).then(async r => {
                  await AnexoBusiness.listar(entidade, this.id).then(l => anexos = l);
                  this.update(anexos[anexos.length - 1].id);
                  await this.emit('blur');
                  // EventBus.$emit('editor');
                  return r;
              }).catch(e => FrontBusiness.showError('', e.message));
          }
      },

      async remover() {
        if(!this.clearable){
          await AnexoBusiness.deleteById(this.model).catch(e => FrontBusiness.showError('', e.message));
          this.update(undefined);
          await this.emit('blur');
        } else {
          await this.emit('remover-file');
        }

        return true;
        // await EventBus.$emit('editor')

      },

      start() {
        this.$refs.upload.active = true;
      },

      withPopper (dropdownList, component, {width}) {
        /**
         * We need to explicitly define the dropdown width since
         * it is usually inherited from the parent with CSS.
         */
        dropdownList.style.width = width;

        /**
         * Here we position the dropdownList relative to the $refs.toggle Element.
         *
         * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
         * the dropdownList overlap by 1 pixel.
         *
         * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
         * wrapper so that we can set some styles for when the dropdown is placed
         * above.
         */
        const popper = createPopper(component.$refs.toggle, dropdownList, {
          placement: 'top',
          modifiers: [
            {
              name: 'offset', options: {
                offset: [0, -1]
              }
            },
            {
              name: 'toggleClass',
              enabled: true,
              phase: 'write',
              fn ({state}) {
                component.$el.classList.toggle('drop-up', state.placement === 'top')
              },
            }]
        });

        /**
         * To prevent memory leaks Popper needs to be destroyed.
         * If you return function, it will be called just before dropdown is removed from DOM.
         */
        return () => popper.destroy();
      }

    }
  }
</script>

<style lang=scss>

  .img {
    height: 230px;
  }

  .upload {
    width: 100%;

    img {
      width: 100%;
      object-fit: contain;
      height: inherit;
      padding: 10px;
      background: white;
    }

    > .btn {
      position: absolute;
      right: 4px;
      top: 8px;
      width: 28px;
      z-index: 1;
    }
  }

  .vs--disabled .vs__dropdown-toggle, .vs--disabled .vs__clear,
  .vs--disabled .vs__search, .vs--disabled .vs__selected,
  .vs--disabled .vs__open-indicator {
    background-color: transparent !important;
  }

  .vs__dropdown-option--disabled {
    opacity: 0.8 !important;
  }

  .vs__dropdown-menu {
    z-index: 9999999999999 !important;
  }

  .input-tags .vs__selected-options .vs__selected, .v-select .vs__selected-options .vs__selected{
    background: #232931;
  }

  .vs--single.vs--open .vs__selected {
    width: 100%;
  }

  input[type="radio"]:disabled {
      position: absolute;
      opacity: 0;
      + .form-check-label {
        color: darken(#e6e6e6, 40%);
        &:before {
          content: '';
          background: #f4f4f4;
          border-radius: 100%;
          border: 1px solid darken(#e6e6e6, 40%);
          display: inline-block;
          width: .8125em;
          height: .8125em;
          position: relative;
          margin-right: .3em;
        }
      }
      &:checked {
        + .form-check-label {
          &:before {
            box-shadow: inset 0 0 0 1.5px #f4f4f4;
            background: darken(#8d8d8d, 35%);
          }
        }
      }
    }

  //.v-select.drop-up.vs--open .vs__dropdown-toggle {
  //  border-radius: 0 0 4px 4px;
  //  border-top-color: transparent;
  //  border-bottom-color: rgba(60, 60, 60, 0.26);
  //}

  //[data-popper-placement='top'] {
  //  border-radius: 4px 4px 0 0;
  //  border-top-style: solid;
  //  border-bottom-style: none;
  //  box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15)
  //}

  select optgroup{
    background: #000;
    color: #fff;
    font-style: normal;
    font-weight: normal;
  }

</style>
