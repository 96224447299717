<template>
  <consulta :columns="columns" ref="modal" :process="process" id="consultar-protocolo" :titulo="titulo" :grabbable="true"
            :exportar="exportar"
            :custom-actions="actions">

    <template #cabecalho>
      <li class="list-inline-item" v-if="multiplos">
        <a @click.prevent="selecionarTodos()" title="Selecionar Todos" class="icon">
          <i class="fal fa-check-circle"></i>
        </a>
      </li>
      <li class="list-inline-item" v-if="multiplos">
        <a @click.prevent="selecionarTodos(true)" title="Limpar Seleção" class="icon">
          <i class="fal fa-circle"></i>
        </a>
      </li>

    </template>

    <template #filtros>

      <template v-if="!$root.config.geral.desativarModeloAntigo">
        <label for="modo-pesquisa" v-if="filtros.dominio == 'PROTOCOLO_RI' || filtros.dominio == 'CERTIDAO_RI'">
          <input id="modo-pesquisa" type="checkbox" v-model="mode" true-value="novo" false-value="default" class="mt-2 mr-1">
          Utilizar novo modelo de consulta
        </label>
      </template>

      <div class="w-100"></div>

      <filtrar-consulta class="w-100" v-if="mode != 'default'" :options="opcoesConsulta" :dto="filtros">
        <template #custom="{item}">

          <v-input type="v-select"  :options="tipoRegistro"
                   v-model="item.value" v-if="item.id == 'tipo_registro'" class="col-12" />

          <v-input type="v-multi-select" :options="tags"
                   v-model="item.value" v-if="item.id == 'tag'" class="col-12" />

        </template>
      </filtrar-consulta>

      <template v-if="mode == 'default'">

        <div class="w-100"></div>
        <v-input type="text" label="Código" v-model="filtros.codigo" class="col" />
        <v-input type="text" label="Protocolo ONR" v-model="filtros.protocoloExterno" class="col"/>
        <v-input type="select" :options="status" label="Status" v-model="filtros.status" class="col" />
        <v-input v-if="tipoProtocolo == 'ORCAMENTO'" :disabled="disabledDominio" type="select" :options="tiposDominio" label="Tipo" v-model="filtros.dominio" class="col-2" @change="changeDominio()" />

        <v-input type="date" label="Data Cadastro (Início) " v-model="filtros.cadastroInicio" class="col-2" />
        <v-input type="date" label="Data Cadastro (Fim)" v-model="filtros.cadastroFim" class="col-2" />

        <template v-if="['CANCELADO', 'FINALIZADO'].includes(filtros.status)">
          <v-input type="date" label="Encerramento (Início) " v-model="filtros.encerramentoInicio" class="col-2" />
          <v-input type="date" label="Encerramento (Fim)" v-model="filtros.encerramentoFim" class="col-2" />
          <div class="w-100"></div>
        </template>

        <template v-if="filtros.dominio == 'PROTOCOLO_RI' && (filtros.tipoProtocolo == 'NORMAL' || filtros.tipoProtocolo == 'PROCESSO_INTERNO')">

          <div class="w-100"></div>

          <v-input type="date" label="Data Registro (Início) " v-model="filtros.registroInicio" class="col-2" />
          <v-input type="date" label="Data Registro (Fim)" v-model="filtros.registroFim" class="col-2" />

          <v-input type="date" label="Prazo de Qualificação (Início)" v-model="filtros.qualificacaoInicio" class="col-4" />
          <v-input type="date" label="Prazo de Qualificação (Fim)" v-model="filtros.qualificacaoFim" class="col-4" />

          <div class="w-100" />

          <v-input type="date" label="Data Reingresso (Início) " v-model="filtros.reingressoInicio" class="col-2" />
          <v-input type="date" label="Data Reingresso (Fim)" v-model="filtros.reingressoFim" class="col-2" />
        </template>

        <template>
          <template v-if="(filtros.tipoProtocolo == 'NORMAL' || filtros.tipoProtocolo == 'PROCESSO_INTERNO')">

            <v-input type="date" label="Data Vencimento (Início) " v-model="filtros.vencimentoInicio" class="col-2" />
            <v-input type="date" label="Data Vencimento (Fim)" v-model="filtros.vencimentoFim" class="col-2" />

          </template>

          <template v-if="filtros.dominio == 'PROTOCOLO_RI' && (filtros.tipoProtocolo == 'NORMAL' || filtros.tipoProtocolo == 'PROCESSO_INTERNO')">

            <v-input type="v-select" :options="options.motivosSuspensao"
                     label="Suspensão de Vencimento" class="col" :clearable="true" v-model="filtros.cancelamentoAutomatico" />

          </template>
        </template>

        <template v-if="(filtros.tipoProtocolo == 'NORMAL' || filtros.tipoProtocolo == 'PROCESSO_INTERNO')">

          <v-input type="date" label="Vencimento Após Reingresso (Início) " v-model="filtros.posReingressoInicio" class="col-4" />
          <v-input type="date" label="Vencimento Após Reingresso (Fim)" v-model="filtros.posReingressoFim" class="col-4" />

        </template>

        <div class="w-100"></div>
        <v-input type="select" label="Etapa" v-model="filtros.etapa_id" :options="etapas" class="col-6" :disabled="tipoProtocolo == 'ORCAMENTO' && filtros.dominio == null" />
        <v-input type="date" label="Movimentação Etapa (Início)" v-model="filtros.andamentoInicio" class="col-3" :disabled="tipoProtocolo == 'ORCAMENTO' && filtros.dominio == null" />
        <v-input type="date" label="Movimentação Etapa (Fim)" v-model="filtros.andamentoFim" class="col-3" :disabled="tipoProtocolo == 'ORCAMENTO' && filtros.dominio == null" />

        <div class="w-100"></div>
        <v-input type="v-select" :options="tipos" label="Serviço Principal" class="col" ref="tipoServico"
                 v-model="filtros.tipo_servico_id" />

        <v-input type="select" label="Atendente" v-model="filtros.atendente_id" :options="atendentes" class="col" />

        <v-input type="select" label="Responsável Atual" v-model="filtros.usuario_id" :options="atendentes" class="col" />

        <div class="w-100"></div>

        <v-input type="text" label="Interessado" v-model="filtros.interessado" class="col" v-if="showInteressado" />
        <v-input type="text" label="Solicitante" v-model="filtros.solicitante" class="col" />
        <v-input type="text" label="Tomador" v-model="filtros.tomador" class="col" v-if="mostrarNotaFiscal" />
        <div class="w-100"></div>

        <card class="card-full card-envolvido mt-2" titulo="Envolvidos" v-if="filtros.dominio == 'PROTOCOLO_RI'">
          <card-content class="row gutters-xs">
            <v-input type="text" label="CPF/CNPJ" v-model="filtros.envolvidoDocumento" class="col-3" />
            <v-input type="text" label="Nome" v-model="filtros.envolvidoNome" class="col" />
          </card-content>
        </card>

        <div class="w-100"></div>
        <v-input type="text" label="Anotações" v-model="filtros.anotacoes" class="col"/>

        <div class="w-100"></div>
        <v-input type="text" label="Exigência" v-model="filtros.exigencia" class="col" v-if="filtros.dominio == 'PROTOCOLO_RI' && filtros.tipoProtocolo == 'NORMAL'"/>

        <div class="w-100"></div>

        <v-input type="v-select" v-if="filtros.dominio == 'PROTOCOLO_RI'" :options="tipoRegistro"
                 v-model="filtros.tipoRegistro" label="Tipo de Registro" class="col-12" />

        <v-input type="v-multi-select" :options="tags"
                 v-model="filtros.tags" label="Tags" class="col-12" />

        <v-input type="text" v-model="filtros.numeroControleExterno" label="Número de Controle Externo" class="col-12" v-if="filtros.dominio == 'PROTOCOLO_RI' || filtros.dominio == 'CERTIDAO_RI'" />

      </template>
    </template>

    <template #row="{ row }">

      <tr>

        <td width="50px" class="text-center" v-if="multiplos">
          <input :id="`check-${row.id}`" class="form-check-input" @click="selectProtocolo(row)"
                 style="margin: -5px 0 0 -6px;position: relative;" type="checkbox" data-testid="check" v-model="row.selecionado">
        </td>

        <td width="50px" class="text-center">
          <router-link :to="{ name: 'protocolo', params:{id : row.id} }" class="icon" target="_blank" @click.native="$event.stopImmediatePropagation()">
            <i class="fas fa-external-link-alt"></i>
          </router-link>
        </td>

        <td width="155px" class="pointer icon" @click="select(row)">
          <a>{{ row.codigo | inteiro }}{{row.letra ? `-${row.letra}` : ''}}</a>
        </td>

        <td v-if="tipoCadastro != null">{{row.dominio == 'PROTOCOLO_RI' ? 'AC00' : ''}}{{ row.protocoloExterno }}</td>

        <td>{{ row.tipoServico }}</td>
        <td>{{ row.etapa || '-' }}</td>
        <td>{{ row.usuario || '-' }}</td>
        <td v-if="showInteressado">{{ row.interessado || '-' }}</td>
        <td>{{ row.solicitante || '-' }}</td>
        <td v-if="mostrarNotaFiscal">{{ row.tomador || '-' }}</td>
        <td width="120px">{{ row.status.capitalize() }}</td>
        <td width="120px" v-if="!filtros.status || filtros.status === 'VIGENTE'">{{ row.status === 'VIGENTE' ? row.duracaoEtapa || '-' : '-' }}</td>
        <td width="120px" v-if="!filtros.status || filtros.status === 'VIGENTE'">{{ row.status === 'VIGENTE' ? row.duracaoVigente || '-' : '-' }}</td>
        <td width="150px">{{ row.cadastro | datahora }}</td>
        <td width="150px">{{ row.encerramento | datahora }}</td>
        <td width="150px" v-if="protocoloRI">{{row.qualificacao | data}}</td>
        <td width="150px">{{ row.vencimento | data }}</td>
        <td  v-if="protocoloRI">{{row.tipoRegistro}}</td>
        <td width="150px">{{row.tags}}</td>
      </tr>

    </template>

    <template #footer v-if="!$root.usuarioLogado.auditor">

      <v-button v-if="marcados.length > 0" class="btn btn-outline-primary mr-2 ml-auto" :run="select" data-testid="adicionar" :params="[marcados]">
        Adicionar
      </v-button>

    </template>

  </consulta>
</template>

<script>
import ConfiguracaoBusiness from "@/business/ConfiguracaoBusiness.js";
import EtapaBusiness from '@/business/crud/EtapaBusiness.js';
import NaturezaBusiness from "@/business/crud/NaturezaBusiness.js";
import TipoServicoBusiness from "@/business/crud/TipoServicoBusiness.js";
import UsuarioBusiness from '@/business/crud/UsuarioBusiness.js';
import FrontBusiness from "@/business/FrontBusiness.js";
import DominioBusiness from "@/business/protocolo/DominioBusiness.js";
import ProtocoloBusiness from "@/business/protocolo/ProtocoloBusiness.js";
import RelatorioBusiness from "@/business/RelatorioBusiness.js";
import Utils from "@/commons/Utils.js";
import ArispAcompanhamentoRegistral from "@/components/Arisp/ArispAcompanhamentoRegistral.vue";
import ArispAtualizarProtocolos from "@/components/Arisp/ArispAtualizarProtocolos.vue";
import ArispImportarPedidosProtocolo from "@/components/Arisp/ArispImportarPedidosProtocolo.vue";
import FiltrarConsulta from "@/components/Consultas/components/FiltrarConsulta.vue";
import Consulta from "@/components/Consultas/Consulta.vue";
import Card from "@/components/Layout/components/Card.vue";
import CardContent from "@/components/Layout/components/CardContent.vue";
import ModalProtocoloAntigo from "@/components/ModalProtocoloAntigo.vue";
import TagBusiness from "@/business/TagBusiness.js";
import TabelaCustaBusiness from "@/business/TabelaCustaBusiness";
import ArquivoRelatorioBusiness from "@/business/relatorios/ArquivoRelatorioBusiness";
import ConsultarRelatorio from "@/components/Consultas/ConsultarRelatorio.vue";
import ModalAuditorias from "@/components/ModalAuditorias.vue";
import AuditoriaBusiness from "@/business/AuditoriaBusiness";

export default {
  name: "ConsultarProtocolo",
  components: {CardContent, FiltrarConsulta, Consulta},

  props: {
    dominio: String,
    tipoProtocolo: {type : String, default : 'NORMAL'},
    tipoCadastro: String,
    tituloPersonalizado : String,
    multiplos : {type : Boolean, default : false},
    disabledDominio : {type : Boolean, default : false},
    filtrosIniciais: {type: Array, default: () => []},
  },

  modal: {
    width: 1300,
    escapable: false
  },

  data() {
    return {
      mode: 'novo',
      moduloNotaFiscalAtivo: false,
      opcoesConsulta: [],
      filtros: {
        filtros: [],
        codigo: null,
        dominio: this.dominio,
        tipoProtocolo: this.tipoProtocolo,
        tipoCadastro: this.tipoCadastro,
        envolvidoDocumento: null,
        envolvidoNome: null,
        tags: [],
        tipoRegistro:[]

      },
      numeroControleExterno: null,
      options: {
        protocolo: [],
        certidao: [],
        motivosSuspensao : [...[{id: 'TODOS', nome: 'Todos'}], ...ProtocoloBusiness.getTipoCancelamentoAutomatico('Não suspensos')],
      },
      etapas: [],
      tags: [],
      tipoRegistro:[],
      todosAtendentes: [{id: 'TODOS', nome: 'Todos'}],
      atendentes: this.todosAtendentes,
      tiposDominio: DominioBusiness.getSimpleDominiosAtivos(),
      status: [{id: 'TODOS', nome: 'Todos'}, ...ProtocoloBusiness.getStatus()],
      marcados: []
    }
  },

  computed: {

    titulo() {
      return this.tituloPersonalizado || 'Consultar ' + FrontBusiness.nomearProtocolo(this.filtros.dominio, this.filtros.tipoProtocolo);
    },

    tituloAntigo() {
      return 'Cadastrar ' + FrontBusiness.nomearProtocolo(this.filtros.dominio, this.filtros.tipoProtocolo) + ' Manualmente';
    },

    showInteressado() {
      return this.tipoProtocolo !== 'PROCESSO_INTERNO';
    },

    mostrarNotaFiscal(){
      return this.moduloNotaFiscalAtivo && this.tipoProtocolo != 'PROCESSO_INTERNO';
    },

    protocoloRI() {
      return this.filtros.dominio === 'PROTOCOLO_RI' && (this.filtros.tipoProtocolo === 'NORMAL' || this.filtros.tipoProtocolo === 'PROCESSO_INTERNO');
    },

    columns() {
      let cols = [];
      if(this.multiplos){
        cols = [
          {label: '', field: 'id', headerClass: 'column__botao', sortable: false, name: 'id'},
        ];
      }

      cols.push(
        {label: '', field: 'id', headerClass: 'column__botao', sortable: false, name: 'id'},
        {label: 'Código', field: 'p.codigo', name: 'codigo'},
      );

      if (this.tipoCadastro != null) {
        cols.push({label: 'Protocolo ONR', field: 'p.protocolo_externo', name: 'protocoloExterno', minWidth: '180px'});
      }

      cols.push({label: 'Serviço', field: 'ts.nome', name: 'tipoServico', minWidth: '200px'});
      cols.push({label: 'Etapa', field: 'e.nome', name: 'etapa', minWidth: '150px'});
      cols.push({label: 'Responsável Atual', field: 'u.nome', name: 'usuario', minWidth: '200px'});

      if (this.showInteressado) {
        cols.push({label: 'Interessado', field: '', name: 'interessado', minWidth: '250px'})
      }

      cols.push({label: 'Solicitante', field: '', name: 'solicitante', minWidth: '250px'})

      if (this.mostrarNotaFiscal) {
        cols.push({label: 'Tomador', field: '', name: 'tomador', minWidth: '250px'})
      }

      cols.push({label: 'Status', field: 'p.status', name: 'status'});
      if (!this.filtros.status || this.filtros.status === 'VIGENTE') {
        cols.push({label: 'Dias na Etapa', field: 'duracaoEtapa', name: 'duracaoEtapa', minWidth: '125px'});
        cols.push({label: 'Dias Vigente', field: 'duracaoVigente', name: 'duracaoVigente', minWidth: '120px'});
      }

      cols.push({label: 'Cadastro', field: 'p.cadastro', name: 'cadastro', minWidth: '150px'});
      cols.push({label: 'Encerramento', field: 'p.encerramento', name: 'encerramento', minWidth: '150px'});
      if(this.protocoloRI) {
        cols.push({label: 'Prazo de Qualificação', field: 'p.prazo_qualificacao', name: 'qualiificacao',  minWidth:'210px'})
      }
      cols.push({label: 'Vencimento', field: 'p.vencimento', name: 'vencimento', minWidth: '150px'});
      if(this.protocoloRI){
        cols.push({label: 'Tipo de Registro', field: 'tipoRegistro', name: 'tipoRegistro', minWidth: '150px'});
      }
      cols.push({label: 'Tags', field: 'tags', name: 'tags', minWidth: '500px', sortable: false});
      return cols;
    },

    tipos() {
      return DominioBusiness.isPedidoCertidao(this.filtros.dominio) ? this.options.certidao :
        this.options.protocolo.filter(n => {
          return n?.opcoes?.tiposDocumento?.includes(this.filtros.tipoProtocolo !== 'PROCESSO_INTERNO' ? 'NORMAL' : 'PROCESSO_INTERNO');
        });
    },

    naturezas() {
      return this.options.naturezas.filter(n => {
        return n?.tiposProtocolo?.includes(this.tipoProtocolo);
      });
    },

    auditor() {
      return this.$root.usuarioLogado.auditor;
    },

    exportar(){
      let forms = {
        direction:"desc",
        sort:"p.codigo",
        filtros: [...this.filtros?.filtros].filter(e => e.id),
        dominio: this.dominio,
        tipoProtocolo: this.tipoProtocolo,
        tipoCadastro: this.tipoCadastro
      };
      console.log(forms)
      return (this.mode === 'novo' && !this.auditor) ? {id: "EXPORTAR_PROTOCOLO", forms, extensao: "XLSX"} : null;
    },

    actions() {
      let actions = [];
      if (this.auditor) return actions;
      if (!this.tipoCadastro) {
        actions.push({
          class: 'at-options',
          icon: 'far fa-plus-square mr-1',
          label: this.tituloAntigo,
          action: this.cadastrarAntiga
        });
      }
      if(this.tipoCadastro === 'ARISP') {
        actions.push({
          class: 'at-options',
          icon: 'far fa-cloud-upload mr-1',
          label: 'Importar Pedidos',
          popover: true,
          action: this.importarPedidosArisp
        });
        actions.push({
          class: 'at-options',
          icon: 'far fa-sync-alt mr-1',
          label: 'Atualizar Pedidos',
          popover: true,
          action: this.atualizarProtocolosArisp
        });
        if (this.filtros.dominio == 'PROTOCOLO_RI') {
          actions.push({
            class: 'at-options',
            icon: 'far fa-walking mr-1',
            label: 'Acompanhamento Registral',
            popover: true,
            action: this.atualizarAcompanhamentoArisp
          });
        }
        actions.push({
          class: 'at-options',
          icon: 'far fa-table-list mr-1',
          label: 'Auditoria',
          action: this.auditoriaArisp
        });
      }
      return actions;
    },

  },

  async mounted() {

    if (this.filtros.tipoProtocolo === "ORCAMENTO") {
      this.opcoesConsulta = [{id: 'dominio', nome: 'Tipo', type: 'list', options: this.tiposDominio}];
      this.filtros.filtros = [{
        id: "dominio",
        type: null,
        value: this.dominio,
        hiddenActions: true,
        disabled: this.disabledDominio
      }];
    }
    this.load();
    this.moduloNotaFiscalAtivo = await ConfiguracaoBusiness.moduloNotaFiscalAtivo();

    this.filtrosIniciais.map(filtro => {
      this.filtros[filtro.idAntigo] = filtro.value;
      this.filtros.filtros.push({id: filtro.id, type: null, value: filtro.value, hiddenActions: true, disabled: false})
    })
  },

  methods: {
    async load() {
      let p1 = TipoServicoBusiness.getByDominioSimples(this.dominio).then(l => this.options.protocolo = Utils.orderBy(l, 'nome'));
      let p2 = TipoServicoBusiness.getByDominioSimples('CERTIDAO_RI').then(l => this.options.certidao = Utils.orderBy(l, 'nome'));
      let p3 = TabelaCustaBusiness.getTiposRegistro(this.dominio).then(l => this.tipoRegistro = l);
      let tags = await TagBusiness.getAll();
      this.tags = tags.filter(t => t.tipoVinculo.includes('PROTOCOLO') && t.ativo);

      let usuarios = await UsuarioBusiness.getSimpleList();
      this.atendentes = usuarios;
      this.atendentes = this.todosAtendentes.concat(this.atendentes);
      this.$set(this.filtros, 'usuario_id', this.atendentes[0].id);
      this.$set(this.filtros, 'atendente_id', this.atendentes[0].id);
      this.$set(this.filtros, 'status', this.status[0].id);
      await this.changeDominio();

      await Promise.all([p1, p2, p3]);

      let opcoes = [
        {id: 'usuario_id', nome: 'Responsável Atual', type: 'list', options: usuarios},
        {id: 'etapa_id', nome: 'Etapa Atual', type: 'list', options: this.etapas.slice(1)},
        {id: 'tipo_servico_id', nome: 'Serviço Principal', type: 'list', options: this.tipos},
        {id: 'atendente_id', nome: 'Atendente', type: 'list', options: usuarios},
        {id: 'tag', nome: 'Tag', type: 'tag', options: this.tags},
        {id: 'status', nome: 'Status/Situação', type: 'list', options: ProtocoloBusiness.getStatus()},
        {id: 'cadastro', nome: 'Data de Cadastro', type: 'date'},
        {id: 'codigo', nome: 'Código', type: 'number'},
        {id: 'dados_importados', nome: 'Dados Importados', type: 'text'},

        {id: 'envolvido_cpf',  nome: 'CPF Envolvido', type: 'cpf'},
        {id: 'envolvido_cnpj', nome: 'CNPJ Envolvido', type: 'cnpj'},
        {id: 'envolvido_nome', nome: 'Nome Envolvido', type: 'text'},

        {id: 'anotacoes', nome: 'Anotações', type: 'tsquery'},
        {id: 'certidao',  nome: 'Teor da Certidão', type: 'tsquery'},
        {id: 'exigencia', nome: 'Exigência', type: 'tsquery'},
        {id: 'protocolo_externo',  nome: 'Protocolo ONR', type: 'simpleString'},
        {id: 'prazo_pos_reingresso', nome: 'Vencimento Após Reingresso', type: 'date'},
        {id: 'numero_controle_externo',  nome: 'Número De Controle Externo', type: 'text'}
      ];

      if(this.tipoProtocolo == 'ORCAMENTO'){
        opcoes.push({id: 'dominio', nome: 'Tipo', type: 'list', options: this.tiposDominio});
      }

      if(this.filtros.dominio !== null){
        opcoes.push({id: 'andamento', nome: 'Data de Movimentação de Etapa', type: 'date'});
      }

      if(this.$root.isEstadoGO && this.filtros.dominio == 'PROTOCOLO_RI'){
        opcoes.push({id: 'prenotacao_sem_selagem', nome: 'Prenotação sem Selagem', type: 'fixed'});
      }

      if (this.showInteressado) {
        opcoes.push({id: 'interessado_id', nome: 'Interessado', type: 'text'});
      }
      opcoes.push({id: 'solicitante_id', nome: 'Solicitante', type: 'text'});
      opcoes.push({id: 'encerramento', nome: 'Data de Encerramento', type: 'date'});

      if (this.filtros.tipoProtocolo == 'NORMAL' || this.filtros.tipoProtocolo == 'PROCESSO_INTERNO') {
        opcoes.push({id: 'vencimento', nome: 'Data de Vencimento', type: 'date'});
      }

      if(this.filtros.dominio == 'PROTOCOLO_RI' && (this.filtros.tipoProtocolo == 'NORMAL' || this.filtros.tipoProtocolo == 'PROCESSO_INTERNO')){
        opcoes.push({id: 'cancelamento_automatico', nome: 'Suspensão de Vencimento', type: 'list', options: this.options.motivosSuspensao});
        opcoes.push({id: 'registro', nome: 'Data de Registro', type: 'date'});
        opcoes.push({id: 'prazo_qualificacao', nome: 'Prazo de Qualificação', type: 'date'});
        opcoes.push({id: 'reingresso', nome: 'Data de Reingresso', type: 'date'});
      }

      if(this.$root.isEstadoBA || this.$root.isEstadoSE) {
        opcoes.push({id: 'guia_daje', nome: 'Guia Daje', type: 'simpleString'});
      }

      if(DominioBusiness.isProtocolo(this.filtros.dominio)){
        await NaturezaBusiness.getByDominioSimples(this.filtros.dominio).then(l => this.options.naturezas = Utils.orderBy(l, 'nome'));
        opcoes.push({id: 'natureza_id', nome: 'Natureza', type: 'list', options: this.naturezas});

        if(this.$root.isEstadoSC) {
          opcoes.push({id: 'sem_taxa_cancelamento', nome: 'Sem Taxa de Cancelamento', type: 'fixed'});
        }

      }

      if(this.filtros.tipoProtocolo == 'NORMAL'){
        opcoes.push({id: 'recibo_numero', nome: 'Número do Recibo', type: 'number'});

        if(this.$root.isEstadoCE) {
          opcoes.push({id: 'identificacao_estadual', nome: 'Recibo TJCE', type: 'tsquery'});
        }

        if(this.$root.isEstadoPE){
          opcoes.push({id: 'sicase', nome: 'Guia Sicase', type: 'number'});
        }

      }

      if (this.filtros.dominio == 'PROTOCOLO_RI' && (this.filtros.tipoProtocolo == 'NORMAL' || this.filtros.tipoProtocolo == 'PROCESSO_INTERNO' || this.filtros.tipoProtocolo == 'ORCAMENTO' || this.filtros.tipoProtocolo == 'EXAME_CALCULO')){
        opcoes.push({id: 'tipo_registro', nome: 'Tipo de Registro', options: this.tipoRegistro});
      }

      this.opcoesConsulta = Utils.orderBy(opcoes, 'nome');
    },

    close() {
      FrontBusiness.closeModal();
    },

    select(item) {
      FrontBusiness.closeModal(item);
    },

    getFiltros(){
      let filtros = Utils.clone(this.filtros);
      if (filtros.usuario_id == 'TODOS') {
        filtros.usuario_id = null;
      }
      if (filtros.status == 'TODOS') {
        filtros.status = null;
      }
      if (filtros.etapa_id == 'TODAS') {
        filtros.etapa_id= null;
      }
      if (filtros?.codigo) {
        filtros.codigo = Utils.stringCodigosToArray(filtros?.codigo);
      }
      if (filtros.dominio != 'PROTOCOLO_RI') {
        filtros.envolvidoDocumento = null;
        filtros.envolvidoNome = null;
      }
      if (filtros.atendente_id == 'TODOS') {
        filtros.atendente_id = null;
      }
      return filtros;
    },

    async process(sort_by, sort_dir, page_length, page_number) {

      if(this.mode == 'novo'){

        let filtros = Utils.clone([...this.filtros?.filtros].filter(e => e.id));
        let dominio = this.dominio;
        let tipoProtocolo = this.tipoProtocolo;
        let tipoCadastro = this.tipoCadastro;

        return ProtocoloBusiness.paginationV2(sort_by, sort_dir, page_number, page_length, {filtros, dominio, tipoProtocolo, tipoCadastro})
          .then(result => ({rows: result.pagina, total: result.quantidade}));
      }

      let filtros = this.getFiltros();
      return ProtocoloBusiness.pagination(sort_by, sort_dir, page_number, page_length, {filtros: filtros})
        .then(result => ({rows: result.pagina, total: result.quantidade}));
    },

    async changeDominio() {
      this.$set(this.filtros, 'tipo_servico_id', null);

      let etapas = await EtapaBusiness.getByDominio(this.filtros.dominio);
      if (this.tipoProtocolo == 'ORCAMENTO') {
        etapas = etapas.filter(e => e.tiposProtocolo.includes('ORCAMENTO'))
      }
      this.etapas = Utils.orderBy(etapas, 'nome');
      this.etapas = ([{id: 'TODAS', nome: 'Todas'}]).concat(this.etapas);
      this.$set(this.filtros, 'etapa_id', this.etapas[0].id);

    },

    async importarPedidosArisp(){
      await FrontBusiness.openModal(ArispImportarPedidosProtocolo, {dominio : this.dominio});
    },

    async atualizarProtocolosArisp(){
      await FrontBusiness.openModal(ArispAtualizarProtocolos, {dominio : this.dominio});
    },

    async atualizarAcompanhamentoArisp(){
      await FrontBusiness.openModal(ArispAcompanhamentoRegistral);
    },

    async auditoriaArisp(){
      await FrontBusiness.openModal(ModalAuditorias, {entidade: AuditoriaBusiness.enumEntidadeExecucao({entidade: 'EXECUCAO_ARISP', dominio: this.dominio})});
    },

    selectProtocolo(protocolo) {
      let index = this.marcados.findIndex(e => e.id === protocolo.id);
      if (index >= 0) {
        this.marcados.splice(index, 1)
      } else {
        this.marcados.push(protocolo)
      }
    },

    selecionarTodos(limpar = false) {
      this.marcados = [];
      this.$refs?.modal?.$refs?.paginator?.rows.forEach(r => {
        let input = document.getElementById(`check-${r.id}`)
        input.checked = !limpar;
        if (!limpar) this.marcados.push(r)
      })
    },

    async cadastrarAntiga(){
      const id = await FrontBusiness.openModal(ModalProtocoloAntigo, {dominio: this.dominio, tipoProtocolo: this.tipoProtocolo, tipoCadastro: this.tipoCadastro});
      if(id){
        FrontBusiness.redirect(`protocolo/${id}/geral`);
      }
    },
  }
}
</script>

<style lang=scss>
#consultar-indicador-pessoal {
  th.column__botao {
    width: 50px;
  }
}
</style>
